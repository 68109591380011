import { STRIPE_PUBLIC_KEY } from "../settings"

export default class StripeHandler {

  declare handler: StripeCheckoutHandler
  declare form?: HTMLFormElement
  declare stripeParams?: StripeCheckoutOptions

  constructor(form: HTMLFormElement, params: StripeCheckoutOptions) {
    this.form = form
    this.stripeParams = params

    this.handler = StripeCheckout.configure({
      key: STRIPE_PUBLIC_KEY,
      token: (token) => this.submitForm(token),
    })
  }

  open() {
    const stripeParams = {
      name: "Allocate",
      image: "/assets/allocate-logo-square-dark.png",
      ...this.stripeParams
    }

    this.handler.open(stripeParams)
  }

  submitForm(token: stripe.Token) {
    this.tokenFields(token).each(([k, v]: [string, string]) => {
      const input = document.createElement("input")

      input.setAttribute("type", "hidden")
      input.setAttribute("name", `token${k}`)
      input.setAttribute("value", decodeURIComponent(v))

      this.form?.appendChild(input)
    })

    this.form?.submit()
  }


  tokenFields(token: stripe.Token): _.IterateeResult<string, string> {
    return _($.param(token).split("&"))
      .map((s) => s.split("="))
      .map(([k, v]) => {
        const [head, ...rest] = decodeURIComponent(k).split("[")
        let dk = `[${head}]`

        if (!_.isEmpty(rest)) {
          // deliberatly leaving off the final ']'
          dk += "[" + rest.join("[")
        }

        return [dk, decodeURIComponent(v)]
      })
  }

}
