import { avatar_board_resource_path, board_resource_path, board_resource_tags_path, board_resource_tag_path } from "@/routes"
import debounce from "@/util/debounce"
import fetch from "@/util/fetch"
import ApplicationController from "./controller"
import { renderStreamMessage } from "@hotwired/turbo"

// Connects to data-controller="resource-edit"
export default class extends ApplicationController<HTMLElement> {
  static values = {
    resourceId: Number,
    boardId: Number
  }

  static targets = [
    "nameInput",
    "emailInput",
    "errors",
    "avatarImage",
    "avatarInput",
    "avatarLoader",
    "tagInput"
  ]

  declare nameInputTarget: HTMLInputElement
  declare emailInputTarget: HTMLInputElement
  declare errorsTarget: HTMLElement
  declare avatarImageTarget: HTMLElement
  declare avatarInputTarget: HTMLInputElement
  declare avatarLoaderTarget: HTMLElement
  declare tagInputTarget: HTMLInputElement

  declare resourceIdValue: number
  declare boardIdValue: number

  connect() {
    this.element.parentElement?.addEventListener("turbo:frame-render", () => this.slide())
  }

  slide() {
    this.element.classList.toggle("shown")
  }

  close() {
    this.slide()
    setTimeout(() => this.clearModalFrame(), 600)
  }

  clearModalFrame() {
    this.element.parentElement?.removeAttribute("src")
    this.element.remove()
  }

  // profile edit methods

  inputName = debounce(() => this.#updateName(), 500)
  inputEmail = debounce(() => this.#updateEmail(), 500)

  #updateName() {
    this.#updateResource({ name: this.nameInputTarget.value ?? "" })
      .then(() => this.#setErrorMessage(""))
      .catch(() => this.#setErrorMessage("Invalid name"))
  }

  #updateEmail() {
    this.#updateResource({ email: this.emailInputTarget.value ?? "" })
      .then(() => this.#setErrorMessage(""))
      .catch(() => this.#setErrorMessage("Invalid email"))
  }

  #setErrorMessage(message: string) {
    this.errorsTarget.innerHTML = message
  }

  async #updateResource(resource: Partial<Resource>, headers?: {}) {
    return fetch(
      board_resource_path(this.boardIdValue, this.resourceIdValue),
      { 
        method: "PUT",
        body: JSON.stringify({
          resource
        }),
        headers
      }
    )
  }

  uploadAvatar() {
    const file = this.avatarInputTarget.files?.item(0)

    if (!file) { return }

    this.avatarLoaderTarget.style.display = "block"
  
    const formData = new FormData()
    formData.append("resource[avatar]", file)

    const headers = new Headers()
    headers.append("Content-Type", "multipart/form-data")

    fetch(
      avatar_board_resource_path(this.boardIdValue, this.resourceIdValue),
      {
        method: "POST",
        body: formData,
        headers
      }
    )
      .then((response) => response.json())
      .then((success) => { 
        this.avatarImageTarget.style.backgroundImage = `url(${success.avatar_url})`
      })
      .catch(() => { 
        alert("Avatar upload failed. Please try again.")
      })
      .finally(() => {
        this.avatarLoaderTarget.style.display = "none"
      })
  }

  deleteResource() {
    if (!confirm(`You are about to delete resource ${this.nameInputTarget.value}. This cannot be undone.`)) {
      return
    }

    fetch(
      board_resource_path(this.boardIdValue, this.resourceIdValue),
      {
        method: "DELETE"
      }
    )
      .then(() => this.close())
      .catch(() => alert(`An error occured when trying to delete resource ${this.nameInputTarget.value}. Please try again.`))
  }


  // tags methods

  tagInput(e: KeyboardEvent) {
    if (e.key !== "Enter" || this.tagInputTarget.value === "") { return }

    const newTag = this.tagInputTarget.value

    fetch(
      board_resource_tags_path(this.boardIdValue, this.resourceIdValue), 
      {
        method: "POST",
        body: JSON.stringify({
          tag: newTag
        }),
        headers: {
          "Turbo-frame": "tags_list"
        }
      }
    )
      .then(response => response.text())
      .then(html => {
        renderStreamMessage(html)
        this.tagInputTarget.value = ""
      })
      .catch(() => alert("An error occured while trying to save the new tag. Please try again."))
  }

  removeTag({ target }: { target: HTMLElement }) {
    if (!target.dataset.tagId) { return }

    fetch(
      board_resource_tag_path(this.boardIdValue, this.resourceIdValue, target.dataset.tagId), 
      {
        method: "DELETE",
        headers: {
          "Turbo-frame": "tags_list"
        }
      }
    )
      .then(response => response.text())
      .then(html => renderStreamMessage(html))
      .catch(() => alert("An error occured while trying to remove the tag. Please try again."))
  }


  // archive/unarchive methods

  toggleArchiveConfirm() {
    this.element.classList.toggle("archive-confirm-visible")
  }

  archive() {
    this.#setArchived(new Date())
  }

  unarchive() {
    if (!confirm(`You are about to unarchive resource ${this.nameInputTarget.value}.`)) {
      return
    }

    this.#setArchived(null)
  }

  #setArchived(date: Date | null) {
    this.#updateResource({ archived_at: date })
      .then(() => this.close())
      .catch(() => alert(`Failed to ${date ? "archive" : "unarchive"} resource. Please try again`))
  }
}
