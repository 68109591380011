import { DateTime } from "luxon"
import fetch from "@/util/fetch"
import { board_resource_assignment_path, board_resource_assignments_path } from "@/routes"

class AssignmentCrud {
  create(assignment: Assignment, projectName: string) {
    return fetch(board_resource_assignments_path(assignment.board_id, assignment.resource_id), {
      method: "POST",
      body: JSON.stringify({
        assignment: {
          ...assignment,
          project: {
            name: projectName
          }
        },
      }),
    })
  }

  update(assignment: Assignment & WithDomElement, startTime: DateTime, endTime: DateTime, resourceId?: number) {
    const groupJson = assignment.element.dataset.assignmentGroupValue ?? "[]"
    const assignmentAttributes: Assignment = JSON.parse(assignment.element.dataset.assignmentAttributesValue ?? "{}")

    // Use the attributes to get the project info, since the name / color can be changed without re-rendering the whole
    // assignment again
    const project = assignmentAttributes.project

    return fetch(this.#path(assignment), {
      method: "PUT",
      body: JSON.stringify({
        assignment: {
          ...assignment,
          project,
          new_resource_id: resourceId,
          start_time: startTime,
          end_time: endTime,
        },
        group: JSON.parse(groupJson),
      })
    })
  }

  delete(assignment: Assignment, group?: number[]) {
    return fetch(this.#path(assignment), {
      method: "DELETE",
      body: JSON.stringify({
        group: group
      })
    })
  }

  #path(assignment: Assignment) {
    return board_resource_assignment_path(assignment.board_id, assignment.resource_id, assignment.id)
  }
}

const assignmentCrud = new AssignmentCrud()

export default assignmentCrud
