import ApplicationController from "./controller"
import interact from "interactjs"
import { new_board_resource_assignment_path } from "@/routes"
import fetch from "@/util/fetch"
import timeGrid from "@/grid/time"
import { renderStreamMessage } from "@hotwired/turbo"
import remembered from "@/grid/remembered"
import assignmentCrud from "@/grid/assignment_crud"
import BoardController from "@/controllers/board_controller"

export default class extends ApplicationController<HTMLElement> {
  static targets = ["assignments", "sidebar", "dropzone"]
  static outlets = ["board"]

  static values = {
    id: Number,
    boardId: Number,
  }

  declare readonly assignmentsTarget: HTMLElement
  declare readonly dropzoneTarget: HTMLElement
  declare readonly sidebarTarget: HTMLElement

  declare readonly boardOutlet: BoardController

  declare readonly idValue: number
  declare readonly boardIdValue: number

  connect() {
    interact(this.dropzoneTarget)
      .dropzone({
        ondrop: (event: { relatedTarget: HTMLElement }) => {
          const assignment: Assignment & WithDomElement = {
            ...JSON.parse(event.relatedTarget.dataset.assignmentAttributesValue ?? "{}"),
            element: event.relatedTarget,
          }

          const sidebarWidth: number = this.assignmentsTarget.getBoundingClientRect().x
          const offsetLeft: number = event.relatedTarget.getBoundingClientRect().x - sidebarWidth
          const offsetRight: number = event.relatedTarget.getBoundingClientRect().width + offsetLeft

          const startTime = timeGrid.adjustToOffset(timeGrid.calculateDateFromOffset(offsetLeft))
          const endTime = timeGrid.adjustToOffset(timeGrid.calculateDateFromOffset(offsetRight - 1))

          assignmentCrud.update(assignment, startTime, endTime, this.idValue)
        }
      })
  }

  createAssignment(e: PointerEvent) {
    if (this.boardOutlet.isReadOnly()) {
      return
    }

    const index = timeGrid.calculateDateIndex(e.offsetX)
    const startTime = timeGrid.calculateDateFromIndex(index)
    const endTime = remembered.applyEndTime(startTime, startTime.plus({ days: timeGrid.newAssignmentLength() }))

    fetch(new_board_resource_assignment_path(this.boardIdValue, this.idValue, {
      start_time: startTime.toString(),
      end_time: endTime.toString(),
    }))
      .then(response => response.text())
      .then(html => renderStreamMessage(html))
  }

  setMaxLevel({ detail: maxLevel }: { detail: number }) {
    this.sidebarTarget.style.padding = `${maxLevel * timeGrid.ITERATION_HEIGHT / 2}px 0`
  }
}
