export default function (callback: () => void, timeout: number) {
  let timer: number

  return () => {
    clearTimeout(timer)

    timer = setTimeout(() => {
      callback()
    }, timeout)
  }
}
