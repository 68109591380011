import { renderStreamMessage } from "@hotwired/turbo"
import ApplicationController from "./controller"
import fetch from "@/util/fetch"
import { edit_board_resource_assignment_path } from "@/routes"
import timeGrid from "@/grid/time"
import { DateTime } from "luxon"
import remembered from "@/grid/remembered"
import assignmentCrud from "@/grid/assignment_crud"

export default class extends ApplicationController<HTMLElement> {
  static targets = ["bar", "project", "projectInput"]

  static values = {
    attributes: Object,
    appending: Boolean,
    boardId: Number,
    group: Array
  }

  #changed = false

  // We need to save this for disconnect since it will be removed from DOM before it can fire the event
  // so we save a reference to the parent on connection so we can still dispatch
  #parent!: HTMLElement | null

  declare readonly barTarget: HTMLElement
  declare readonly projectInputTarget: HTMLInputElement

  declare attributesValue: Assignment
  declare readonly boardIdValue: number
  declare readonly groupValue: number[]

  connect() {
    this.#parent = this.element.parentElement

    const parsedData = {
      ...this.attributesValue,
      element: this.element,
    }

    this.dispatch("addAssignment", {
      detail: {
        assignment: parsedData,
      }
    })
  }

  disconnect() {
    if (this.#parent) {
      this.dispatch("removeAssignment", {
        detail: this.attributesValue.id,
        target: this.#parent,
      })
    }
  }

  edit(e: PointerEvent & { target: HTMLElement }) {
    const clickXPos = e.target.offsetLeft + e.offsetX
    const calendarDayIndex = timeGrid.calculateDateIndex(clickXPos)
    const startDate = DateTime.fromSeconds(this.attributesValue.timestamps.start)
    const endDate = DateTime.fromSeconds(this.attributesValue.timestamps.end)

    const canSplit = timeGrid.canSplitAssignment(calendarDayIndex, startDate, endDate)

    fetch(edit_board_resource_assignment_path(
      this.boardIdValue,
      this.attributesValue.resource_id,
      this.attributesValue.id,
      { 
        x: e.clientX,
        y: e.clientY,
        group: this.groupValue,
        ...(canSplit && { split: calendarDayIndex }),
      }))
      .then(response => response.text())
      .then(html => {
        renderStreamMessage(html)
      })
      .catch(() => alert("Failed to save assignment changes."))
  }

  submit() {
    // Submitting a blank project name / name with spaces
    if (this.projectInputTarget.value.trim().length === 0) {
      this.cancel()

      return
    }

    assignmentCrud.create(this.attributesValue, this.projectInputTarget.value)
      .then(response => response.json())
      .then((assignment: Assignment) => {
        remembered.store(assignment)
        this.close()
      })
  }

  cancel() {
    remembered.clear()
    this.close()
  }

  close() {
    this.element.remove()
  }

  changed() {
    this.#changed = true
  }

  clickAway() {
    if (this.#changed) {
      this.submit()
    } else {
      this.cancel()
    }
  }

  async projectTargetConnected(target: HTMLElement) {
    const billableChanged = target.dataset.assignmentProjectBillableChangedValue === "true"

    if (billableChanged) {
      this.dispatchGlobal("updateIterations")
    }

    const colorClass = target.dataset.assignmentColorClassValue

    if (colorClass && !this.barTarget.classList.contains(colorClass)) {
      this.barTarget.classList.forEach((c) => {
        if (c.indexOf("color_") >= 0) {
          this.barTarget.classList.remove(c)
        }
      })

      this.barTarget.classList.add(colorClass)
    }

    // Update our attributes to match as well
    this.attributesValue = {
      ...this.attributesValue,
      project: {
        ...this.attributesValue.project,
        name: target.dataset.assignmentProjectName ?? "",
        color_int: parseInt(target.dataset.assignmentColorIndex ?? "0", 10),
      }
    }
  }

  hide() {
    this.element.style.display = "none"
  }
}
