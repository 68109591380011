import { ActionEvent } from "@hotwired/stimulus"
import ApplicationController from "./controller"
import GALogger from "@/util/ga_logger"

export default class extends ApplicationController<HTMLElement> {
  static targets = ["signUpForm", "email"]
  declare readonly signUpFormTarget: HTMLElement
  declare readonly emailTarget: HTMLElement

  initialize() {
    if (!window.olark) {
      // eslint-disable-next-line
      window.olark = (api_call) => console.log(api_call)
    }
    window.olark("api.box.hide")
  }

  openChat() {
    window.olark("api.box.expand")
  }

  logEvent({ params: { trackData }}: ActionEvent & { params: { trackData: string }}) {
    GALogger.track(trackData.split(", "))
  }

  scrollSignupIntoView() {
    this.signUpFormTarget.scrollIntoView({ block: "start", behavior: "smooth" })
    this.emailTarget.focus({ preventScroll: true })
  }
}
