import ApplicationController from "./controller"
import Sortable from "sortablejs"
import { board_resource_path } from "@/routes"
import fetch from "@/util/fetch"

export default class extends ApplicationController<HTMLElement> {
  static targets = ["row", "archivedRow", "sidebar", "resourceRowsGroup"]

  declare readonly rowTargets: HTMLElement[]
  declare readonly archivedRowTarget: HTMLElement
  declare readonly hasArchivedRowTarget: boolean
  declare readonly resourceRowsGroupTarget: HTMLElement

  static values = {
    readOnly: Boolean
  }

  declare readonly readOnlyValue: Boolean

  #sortable?: Sortable

  rowTargetConnected() {
    this.setArchivedDisplay()
  }

  toggleArchive() {
    if (this.hasArchivedRowTarget) {
      this.element.classList.toggle("closed")
    }
  }

  rowTargetDisconnected() {
    this.setArchivedDisplay()
    this.dispatchGlobal("updateIterations")
  }

  setArchivedDisplay() {
    if (!this.hasArchivedRowTarget) {
      return
    }

    this.archivedRowTarget.style.display = this.rowTargets.length > 0 ? "block" : "none"
  }

  // sortable
  resourceRowsGroupTargetConnected() {
    if (this.readOnlyValue) {
      return
    }

    this.#sortable = new Sortable(this.resourceRowsGroupTarget, {
      filter: ".pencil",
      handle: ".sidebar",

      onStart(event) {
        const row = event.item
        row.style.width = `${window.innerWidth}px`

        // remove the hover-enabled css class from all of the rows
        // otherwise, the hover styles will sometimes activate when the row being sorted
        // is dragged over another row, leading to a weird state where multiple rows have
        // active hover styles
        const allRows = Array.from(document.getElementsByClassName("resource-row"))

        allRows.map(item => item.classList.remove("row-hover-enabled"))
      },

      onEnd(event) {
        const row = event.item
        const newPosition = event.newIndex ?? 0
        const resourceId = parseInt(row.dataset.resourceIdValue ?? "", 10)
        const boardId = parseInt(row.dataset.resourceBoardIdValue ?? "", 10)

        row.style.width = ""

        const resource = {
          position: newPosition
        }

        fetch(board_resource_path(boardId, resourceId), {
          method: "PUT",
          body: JSON.stringify({
            resource
          })
        }).finally(() => {
          // re-enable row hover styles
          const allRows = Array.from(document.getElementsByClassName("resource-row"))

          allRows.map(item => item.classList.add("row-hover-enabled"))
        })
      },
    })
  }
}
