import { Controller } from "@hotwired/stimulus"

interface Events {
  hideModal: undefined
  updateIterations: undefined
  updateTimeGrid: undefined
  resetAssignments: undefined
}

export default class ApplicationController<T extends Element> extends Controller<T> {
  dispatchGlobal<K extends keyof Events>(type: K, detail?: Events[K]) {
    window.dispatchEvent(new CustomEvent(type, { detail }))
  }
}
