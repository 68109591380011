import ApplicationController from "./controller"
import debounce from "@/util/debounce"
import timeGrid, { TimePeriod } from "@/grid/time"

export default class extends ApplicationController<HTMLElement> {
  static targets = [
    "assignment",
    "currentIteration",
    "filterTerm", 
    "filterClear",
    "gridLine",
    "iteration",
    "resource",
    "timenav",
    "todayMarker",
  ]

  static values = {
    readOnly: Boolean
  }

  declare readonly readOnlyValue: boolean

  declare readonly assignmentTargets: HTMLElement[]
  declare readonly currentIterationTarget: HTMLElement
  declare readonly filterTermTarget: HTMLInputElement
  declare readonly filterClearTarget: HTMLElement
  declare readonly gridLineTargets: HTMLElement[]
  declare readonly hasResourceTarget: boolean
  declare readonly resourceTargets: HTMLElement[]
  declare readonly timenavTarget: HTMLElement
  declare readonly todayMarkerTargets: HTMLElement[]

  initialize() {
    this.#toggleFilter()
  }

  assignmentTargetConnected() {
    this.#toggleFilter()
  }

  assignmentTargetDisconnected() {
    this.#toggleFilter()
  }

  resourceTargetConnected() {
    this.#toggleFilter()
  }

  resourceTargetDisconnected() {
    this.#toggleFilter()
  }

  timenavTargetConnected() {
    this.updateTimeGrid()
  }

  updateTimeGrid() {
    const timePeriod = this.timenavTarget.dataset.timeNavPeriodValue === "week" ? TimePeriod.Week : TimePeriod.Month

    timeGrid.setOffset(parseInt(this.timenavTarget.dataset.timeNavOffsetValue ?? "0", 10))
    timeGrid.setPeriod(timePeriod)
    timeGrid.setHideWeekends(this.timenavTarget.dataset.timeNavHideWeekendsValue === "true")

    // Remove Gridlines
    this.gridLineTargets.forEach(line => line.remove())
  }

  iterationTargetConnected(target: HTMLElement) {
    const gridPosition = target.getBoundingClientRect().right
    const line = document.createElement("div")
    line.dataset.boardTarget = "gridLine"
    line.classList.add("grid-line")
    line.style.left = `${gridPosition}px`

    this.element.appendChild(line)
  }

  currentIterationTargetConnected() {
    const positionX = window.scrollX + this.currentIterationTarget.getBoundingClientRect().left

    this.todayMarkerTargets.map(marker => {
      marker.style.backgroundPositionX = `${positionX}px`
      marker.classList.add("on")
    })
  }

  currentIterationTargetDisconnected() {
    this.todayMarkerTargets.map(marker => {
      marker.classList.remove("on")
    })
  }

  // debounce updating the filter input state so we don't update it multiple times
  // when multiple resources/assignments connect at the same time
  #toggleFilter = debounce(() => this.#enableDisableFilter(), 250)

  #enableDisableFilter() {
    if (this.hasResourceTarget) {
      this.filterTermTarget.classList.add("disabled")
      this.filterTermTarget.disabled = false
      this.filterTermTarget.placeholder = "Filter by name, project or tag"
    } else {
      this.filterTermTarget.classList.remove("disabled")
      this.filterTermTarget.disabled = true
      this.filterTermTarget.placeholder = "Add resources to filter..."
    }
  }

  updateSearch() {
    const value = this.filterTermTarget.value

    this.filterClearTarget.classList.toggle("dn", value.trim().length === 0)
    this.#filter(value)
  }

  clearSearch() {
    this.filterTermTarget.value = ""
    this.updateSearch()
  }

  #filter(value: string) {
    // filtered resource map
    // k: resource id, v: true/false that it should be visible
    const filteredResources = new Map<number, boolean>()

    // first, iterate the assignments
    // if we match an assignment project name or note, we want to keep that resource row
    this.assignmentTargets.forEach((assignment) => {
      const assignmentAttributes: Assignment = JSON.parse(assignment.dataset.assignmentAttributesValue ?? "{}")
      const resourceId = parseInt(assignmentAttributes.resource_id ?? "", 10) ?? 0

      const projectElement: HTMLElement | null = assignment.querySelector(".project-name")
      const name = projectElement?.dataset.assignmentProjectName ?? ""
      const note = assignmentAttributes.note ?? ""

      const matchAssignment = this.#includesValue(name, value) || this.#includesValue(note, value)

      if (matchAssignment) {
        filteredResources.set(resourceId, true)
      }
    })

    // next, iterate the resources
    // if we matched an assignment on that resource,
    // or we match the resource name and/or tags, keep that resource
    // otherwise, hide the resource
    this.resourceTargets.forEach((resource) => {
      const resourceElement: HTMLElement | null = resource.querySelector(".resource-sidebar")
      const attributes: Resource = JSON.parse(resourceElement?.dataset.resourceAttributesValue ?? "{}")

      let matchResource = filteredResources.get(attributes.id) ?? false

      // if we didn't match an assignment, check match against name
      if (!matchResource) {
        matchResource = this.#includesValue(attributes.name, value)
      }

      // if we didn't match an assignment or the resource name, check against the tags
      if (!matchResource) {
        matchResource = attributes.tags.some(tag => this.#includesValue(tag.name, value))
      }

      // hide the resource if there is no match
      resource.classList.toggle("hidden-by-filter", !matchResource)
    })
  }

  isReadOnly(): boolean {
    return this.readOnlyValue
  }

  #includesValue(term: string, search: string): boolean {
    return term.toLowerCase().includes(search.toLowerCase().trim())
  }
}
