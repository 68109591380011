export default {
  track: (event: string[]) => {
    if (typeof window._gaq === "undefined") {
      // We are in dev environment
      // eslint-disable-next-line
      console.log(`Tracking Event: ${event.join(", ")}`)
    } else {
      // We are in production environment
      window._gaq.push(["_trackEvent"].concat(event))
    }
  }
}
