import ApplicationController from "./controller"

export default class extends ApplicationController<HTMLElement> {
  static targets = ["dropdown"]
  declare readonly dropdownTarget: HTMLElement

  toggle() {
    this.element.classList.toggle("opened")
  }

  close({ target }: { target: HTMLLabelElement }) {
    if (!this.dropdownTarget.contains(target)) {
      this.element.classList.remove("opened")
    }
  }
}
