import ApplicationController from "./controller"

// Connects to data-controller="workload"
export default class extends ApplicationController<HTMLElement> {
  static targets = [
    "popup",
  ]

  declare readonly popupTarget: HTMLElement

  show() {
    this.popupTarget.classList.remove("hidden")
  }

  close({ target }: { target: HTMLElement }) {
    if (!this.element.contains(target)) {
      this.popupTarget.classList.add("hidden")
    }
  }
}
