import ApplicationController from "./controller"
import { ActionEvent } from "@hotwired/stimulus"
import { board_project_path } from "../routes"
import fetch from "@/util/fetch"

export default class extends ApplicationController<HTMLElement> {
  static targets = ["color", "colorInput", "billableInput"]

  declare readonly colorTargets: HTMLElement[]
  declare readonly colorInputTarget: HTMLInputElement
  declare readonly billableInputTarget: HTMLInputElement

  selectColor({ target }: { target: HTMLLabelElement }) {
    // check if this form has the read-only class, if so then return
    if (target.classList.contains("read-only")) {
      return
    }

    // clear the existing color selection
    this.clearSelectedColor()

    // identify the newly picked color
    const selectedColor = target.getAttribute("data-color-int")

    // set the active class (checkmark) on the newly selected color
    target.classList.add("active")

    // update the hidden input with the new color int value
    if (selectedColor) {
      this.colorInputTarget.value = selectedColor
    }
  }

  clearSelectedColor() {
    this.colorTargets.map(el => el.classList.remove("active"))
  }

  toggleBillable({ target }: { target: HTMLInputElement }) {
    this.billableInputTarget.value = (!target.checked).toString()
  }

  deleteProject({ params: { projectName, projectId, boardId }}: ActionEvent & {
    params: { projectName: string, projectId: number | null, boardId: number | null }
  }) {
    if (
      boardId && projectId &&
      confirm(
        `You are about to delete the ${projectName} project. Are you sure?`
      )
    ) {
      fetch(board_project_path(boardId, projectId, { format: "json" }),
        {
          method: "DELETE",
        })
        .then(() => {
          alert(`${projectName} deleted.`)

          this.dispatchGlobal("hideModal")
        })
        .catch(() => {
          alert(`${projectName} failed to delete.`)
        })
    }
  }
}
