export default function (url: string, options?: RequestInit) {
  const csrfMetaTag: HTMLMetaElement | null = document.querySelector("[name='csrf-token']")
  const csrfToken = csrfMetaTag?.content

  const headers = options?.headers ? new Headers(options.headers) : new Headers()
  headers.set("X-CSRF-Token", csrfToken ?? "")

  if (!headers.has("Content-Type")) {
    headers.set("Content-type", "application/json")
  }
  else if (headers.get("Content-Type") === "multipart/form-data") {
    headers.delete("Content-Type")
  }

  return fetch(url, { ...options, headers })
    .then((response) => response.ok ? response : Promise.reject(response))
}
