import ApplicationController from "./controller"
import { board_projects_path } from "../routes"
import fetch from "@/util/fetch"
import autocomplete, { AutocompleteItem, AutocompleteResult } from "autocompleter"

export default class extends ApplicationController<HTMLInputElement> {
  static values = {
    boardId: Number,
  }

  declare readonly boardIdValue: number

  #autocomplete?: AutocompleteResult

  async connect() {
    const projectNames: AutocompleteItem[] = await fetch(board_projects_path(this.boardIdValue, { format: "json" }))
      .then(response => response.json())
      .then((projects: Project[]) => projects.map((project: Project) => {
        return {
          label: project.name
        }
      }))

    const search = (term: string, label: string) => {
      return label.toLowerCase().startsWith(term.trim().toLowerCase())
    }

    this.#autocomplete = autocomplete<AutocompleteItem>({
      minLength: 1,
      input: this.element,
      disableAutoSelect: true,
      fetch: (text, update: (items: AutocompleteItem[]) => void) => {
        const matches = projectNames.filter(item => search(text, item.label ?? ""))
        matches.sort()

        update(matches.slice(0, 5))
      },
      onSelect: (item: AutocompleteItem) => {
        this.element.value = item.label ?? ""
      }
    })
  }

  disconnect() {
    if (this.#autocomplete) {
      this.#autocomplete.destroy()
      this.#autocomplete = undefined
    }
  }
}
