import ApplicationController from "./controller"
import { split_board_resource_assignment_path } from "../routes"
import fetch from "@/util/fetch"
import timeGrid from "@/grid/time"
import { DateTime } from "luxon"
import { renderStreamMessage } from "@hotwired/turbo"
import remembered from "@/grid/remembered"
import assignmentCrud from "@/grid/assignment_crud"

export default class extends ApplicationController<HTMLElement> {
  static values = {
    assignment: { id: Number, resource_id: Number },
    splitIndex: Number,
    group: Array,
  }

  declare readonly assignmentValue: Assignment
  declare readonly groupValue: number[]
  declare readonly splitIndexValue: number

  splitAllocation() {
    const start = DateTime.fromSeconds(this.assignmentValue.timestamps.start)
    let splitDate = timeGrid.calculateDateFromIndex(this.splitIndexValue, start)

    if (timeGrid.period === "week" && timeGrid.hideWeekends) {
      while (timeGrid.isWeekend(splitDate)) {
        splitDate = splitDate.plus({ days: 1 })
      }
    }

    const splitEndDate = remembered.applyEndTime(splitDate, splitDate)

    // Close modal
    this.dispatchGlobal("hideModal")

    fetch(split_board_resource_assignment_path(this.assignmentValue.board_id, this.assignmentValue.resource_id, this.assignmentValue.id), {
      method: "POST",
      body: JSON.stringify({
        split_date: splitDate.toString(),
        split_date_end: splitEndDate.toString(),
      })
    })
      .then(response => response.text())
      .then(html => renderStreamMessage(html))
  }

  deleteAllocation() {
    assignmentCrud.delete(this.assignmentValue, this.groupValue)
      .then(() => {
        remembered.clear()
        this.dispatchGlobal("hideModal")
      })
      .catch(() => {
        alert("Failed to delete allocation.")
      })
  }
}
