import ApplicationController from "./controller"
import { ActionEvent } from "@hotwired/stimulus"
import timeGrid from "@/grid/time"
import debounce from "@/util/debounce"
import remembered from "@/grid/remembered"
import assignmentRenderer from "@/grid/assignment_renderer"
import solver from "@/grid/solver"
import BoardController from "@/controllers/board_controller"

interface AddAssignmentEvent extends ActionEvent {
  detail: {
    assignment: Assignment & WithDomElement
  }
}

export default class extends ApplicationController<HTMLElement> {
  static targets = ["assignment"]
  static outlets = ["board"]

  declare readonly boardOutlet: BoardController

  #assignments: AssignmentsCollection = {}
  #debounceOverlap = debounce(() => this.solveOverlapping(), 100)

  connect() {
    this.updateTimeGrid()
  }

  addAssignment({ detail: { assignment }}: AddAssignmentEvent) {
    this.#assignments[assignment.id ?? "new"] = assignment

    // Let store decide to update or not in case it's the same assignment that's already remembered
    remembered.update(assignment)

    // Solve overlapping now that new assignment added
    this.#debounceOverlap()

    // Don't let the new assignment be draggable/resizable
    if (!assignment.is_new && !this.boardOutlet.isReadOnly()) {
      assignmentRenderer.makeResizable(assignment, this.element)
      assignmentRenderer.makeDraggable(assignment)
    }
  }

  updateTimeGrid() {
    this.element.style.left = `${timeGrid.timelineOffset()}px`
    this.solveOverlapping()
  }

  solveOverlapping() {
    const { assignments, maxLevel } = solver.calculateOverlapping(this.#assignments, timeGrid.period === "month")

    // Render all the assignments
    Object.values(assignments)
      .map(assignment => assignmentRenderer.render(assignment))

    // Set the assignments div height
    this.element.style.height = `${(maxLevel + 1) * timeGrid.ITERATION_HEIGHT + (timeGrid.ITERATION_HEIGHT / 2)}px`

    // Dispatch the new height to the resource
    this.dispatch("maxLevel", {
      detail: maxLevel
    })
  }

  removeAssignment({ detail: assignmentId }: { detail: number }) {
    delete this.#assignments[assignmentId ?? "new"]

    this.#debounceOverlap()
    this.dispatchGlobal("updateIterations")
  }

  // Clear any calculated dimensions because the period changed
  resetAssignments() {
    Object.values(this.#assignments).map(assignment => assignment.dimensions = undefined)
  }
}
