import ApplicationController from "./controller"
import timeGrid, { TimePeriod } from "@/grid/time"
import { loading_resource_path, loading_show_path } from "@/routes"
import { renderStreamMessage } from "@hotwired/turbo"
import fetch from "@/util/fetch"

interface ChangeEvent {
  detail: {
    offset: number
    period: TimePeriod
    boardId: number
    direction: "left" | "right"
  }
}

export default class extends ApplicationController<HTMLElement> {
  static targets = ["resource"]

  #broadcast = {
    [TimePeriod.Week]: new Set<number>([0]), // Week 0 is loaded initially
    [TimePeriod.Month]: new Set<number>(),
  }

  change({ detail: { offset, period, boardId, direction }}: ChangeEvent) {
    // Don't rebroadcast the same offset again
    if (this.#broadcast[period].has(offset)) {
      return
    }

    this.#broadcast[period].add(offset)

    fetch(loading_show_path(), {
      body: JSON.stringify({
        offset: offset,
        period: period,
        board_id: boardId,
        direction,
      }),
      method: "POST",
    })
      .then(response => response.text())
      .then(html => {
        renderStreamMessage(html)
      })
  }

  resourceTargetConnected(target: HTMLElement) {
    const lazy = target.dataset.loadingLazyValue === "true"

    // Avoid trying to lazy load everything missing on initial load
    if (!lazy) {
      return
    }

    const boardId = parseInt(target.dataset.resourceBoardIdValue ?? "0", 10)
    const resourceId = parseInt(target.dataset.resourceIdValue ?? "0", 10)
    const periodBroadcast = this.#broadcast[timeGrid.period]
    const min = Math.min(...periodBroadcast)
    const max = Math.max(...periodBroadcast)

    // Haven't navigated anywhere, don't do anything
    if (min === 0 && max === 0) {
      return
    }

    fetch(loading_resource_path(), {
      body: JSON.stringify({
        min,
        max,
        board_id: boardId,
        resource_id: resourceId,
        period: timeGrid.period,
      }),
      method: "POST",
    })
      .then(response => response.text())
      .then(html => {
        renderStreamMessage(html)
      })
  }
}
