import { DateTime } from "luxon"
import { MAX_TIME_SPAN } from "@/settings"

export enum TimePeriod {
  Week = "week",
  Month = "month",
}

class TimeGrid {
  readonly ITERATION_WIDTH: number = 34
  readonly ITERATION_HEIGHT: number = 30
  readonly MONTH_MINIMUM_DURATION: number = 3
  readonly NEW_ASSIGNMENT_WEEK: number = 6
  readonly NEW_ASSIGNMENT_MONTH: number = 7

  period: TimePeriod = TimePeriod.Week
  offset: number = 0
  hideWeekends: boolean = false
  firstDayOfGrid!: DateTime
  lastDayOfGrid!: DateTime
  timelineStart: DateTime

  constructor() {
    this.resetFirstDayOfGrid()
    this.timelineStart = this.firstDayOfGrid
  }

  setPeriod(period: TimePeriod) {
    // Only reset period / offset when actually changed
    if (this.period === period) {
      return
    }

    this.period = period
    this.offset = 0
    this.resetFirstDayOfGrid()
    this.timelineStart = this.firstDayOfGrid
  }

  setOffset(offset: number) {
    this.offset = offset
    this.resetFirstDayOfGrid()
  }

  setHideWeekends(hide: boolean) {
    this.hideWeekends = hide
  }

  resetFirstDayOfGrid() {
    if (this.period === TimePeriod.Week) {
      this.firstDayOfGrid = DateTime.now().startOf("week").plus({ weeks: this.offset })
      this.lastDayOfGrid = this.firstDayOfGrid.plus({ weeks: MAX_TIME_SPAN })
    } else {
      let dt = DateTime.now().startOf("month").plus({ months: this.offset })

      // if the first day of the month isn't a monday
      // find the first monday
      if (dt.weekday !== 1) {
        dt = dt.plus({ days: (8 - dt.weekday) })
      }

      this.firstDayOfGrid = dt
      this.lastDayOfGrid = this.firstDayOfGrid.plus({ months: MAX_TIME_SPAN })
    }
  }

  newAssignmentLength() {
    return this.period === TimePeriod.Week ? this.NEW_ASSIGNMENT_WEEK : this.NEW_ASSIGNMENT_MONTH
  }

  unit(period: TimePeriod = this.period) {
    return period === TimePeriod.Week ? "days" : "weeks"
  }

  unitsOffset() {
    // Some months show 4 weeks, some show 5 so we can't really calculate this on a unit basis
    if (this.period === TimePeriod.Month) {
      return this.unitsFromStart(this.firstDayOfGrid)
    }

    return (this.hideWeekends ? 5 : 7) * this.offset
  }

  unitsFromStart(date: DateTime, period: TimePeriod = this.period) {
    const units = -Math.floor(this.timelineStart.diff(date).as(this.unit(period)))

    if (!this.hideWeekends || period === TimePeriod.Month) {
      return units
    }

    return units - this.totalWeekendDaysBetween(this.timelineStart, date)
  }

  textIndent(offset: number) {
    return Math.max(this.unitsOffset() - offset, 0) * this.ITERATION_WIDTH
  }

  calculateDimensions(assignment: Pick<Assignment, "timestamps">, period: TimePeriod = this.period) {
    let start = DateTime.fromSeconds(assignment.timestamps.start)
    let end = DateTime.fromSeconds(assignment.timestamps.end)

    // Round the start date up to first weekday, and end date down
    if (this.hideWeekends && period === TimePeriod.Week) {
      while (this.isWeekend(start)) {
        start = start.plus({ day: 1 })
      }

      while (this.isWeekend(end)) {
        end = end.plus({ day: -1 })
      }
    } else if (period === TimePeriod.Month) {
      start = start.startOf("week")
      end = end.startOf("week")
    }

    const left = this.unitsFromStart(start, period)
    const right = this.unitsFromStart(end, period)

    // We need the extra 1 because the duration needs to include the last day
    const duration = Math.max(1, right - left + 1)

    return {
      offset: left,
      duration,
    }
  }

  isVisible(assignment: Assignment) {
    const gridStart = this.firstDayOfGrid.toSeconds()
    const gridEnd = this.lastDayOfGrid.toSeconds()
    const start  = assignment.timestamps.start
    const end = assignment.timestamps.end

    return (start >= gridStart && start <= gridEnd) // Start is inside
        || (end >= gridStart && end <= gridEnd) // End is inside
        || (start <= gridStart && end >= gridEnd) // Covers entire range
  }

  calculateDateIndex(xPos: number) {
    return Math.floor(xPos / this.ITERATION_WIDTH)
  }

  calculateDateFromIndex(index: number, start: DateTime = this.firstDayOfGrid) {
    if (this.period === TimePeriod.Week && this.hideWeekends) {
      const missingWeekends = Math.floor(index / 5) * 2
      index += missingWeekends
    }

    return this.addPeriodUnits(start, index)
  }

  adjustToOffset(date: DateTime) {
    const unit = this.period === TimePeriod.Week ? "weeks" : "months"

    return date.plus({
      [unit]: -this.offset,
    })
  }

  addPeriodUnits(date: DateTime, units: number) {
    return date.plus({
      [this.unit()]: units
    })
  }

  calculateDateFromOffset(xPos: number) {
    return this.calculateDateFromIndex(this.calculateDateIndex(xPos))
  }

  timelineOffset() {
    return -this.unitsOffset() * this.ITERATION_WIDTH
  }

  canSplitAssignment(clickIndex: number, startDate: DateTime, endDate: DateTime) {
    // can't split on month view
    if (this.period === TimePeriod.Month) {
      return false
    }

    // can't split on first day
    if (clickIndex === 0) {
      return false
    }

    // can't split on last day
    // since all the dates in the db are stored as 00:00:00 time, use endOf day
    const assignmentTotalLength = endDate.endOf("day").diff(startDate, "days").toObject()

    // round up to get the total number of days
    const assignmentTotalDays = Math.ceil(assignmentTotalLength.days ?? 0)

    // subtract 1 from the total days to get the index of the last day
    let lastDayIndex = assignmentTotalDays - 1

    // if weekends are hidden, remove the total days of hidden weekends from the assignment total days
    if (this.hideWeekends) {
      lastDayIndex -= this.totalWeekendDaysBetween(startDate, endDate)
    }

    // if last day index is the click index, we're on the last day and can't split here
    if (clickIndex === lastDayIndex) {
      return false
    }

    return true
  }

  totalWeekendDaysBetween(startDate: DateTime, endDate: DateTime) {
    let weekendDays = 0
    let factor = 1

    // Reverse the dates if the end date is before
    if (endDate < startDate) {
      [endDate, startDate] = [startDate, endDate]
      factor = -1
    }

    while (startDate < endDate) {
      if (this.isWeekend(startDate)) {
        weekendDays += 1
      }
      startDate = startDate.plus({ days: 1 })
    }

    return weekendDays * factor
  }

  isWeekend(date: DateTime) {
    return date.weekday === 6 || date.weekday === 7
  }
}

const timeGrid = new TimeGrid()

export default timeGrid
