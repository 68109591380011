import ApplicationController from "./controller"
import { ActionEvent } from "@hotwired/stimulus"
import StripeHandler from "../util/stripeHandler"

// Connects to data-controller="pricing-overlay"
export default class extends ApplicationController<HTMLElement> {
  static targets = ["planForm", "freePlanForm"]

  static values = {
    newPlanValue: String
  }

  declare readonly planFormTargets: HTMLFormElement[]
  declare readonly freePlanFormTarget: HTMLFormElement
  declare readonly newPlanValue: string

  declare form?: HTMLFormElement
  declare plan?: string

  connect() {
    // if newPlan is set on the top level element, a plan has already been selected, but a card error likely occured. 
    // pre-select the plan and re-trigger stripe
    if (this.newPlanValue) {
      this.selectPlan(this.newPlanValue)
      this.openStripeHandler()
    }
  }
  
  updatePlan({ params: { plan, paymentDetails }}: ActionEvent & { params: { plan: string, paymentDetails: string | null }}) {

    this.selectPlan(plan)

    if (paymentDetails) {
      if (confirm(`Change to \"${this.plan}\" plan?`)) {
        this.form?.submit()
      }
    }
    else {
      this.openStripeHandler()
    }
    
  }

  selectPlan(plan?: string) {
    this.plan = plan
    this.form = this.planFormTargets.find(el => el.dataset.plan === this.plan)
  }

  openStripeHandler() {
    const params: StripeCheckoutOptions = {
      email: this.form?.dataset.email,
      description: `${this.plan} Plan`,
      amount: parseInt(this.form?.dataset.cents ?? "0", 10),
      panelLabel: "Subscribe",
    }

    if (this.form) {
      new StripeHandler(this.form, params).open()
    }
  }

  chooseFreePlan(e: Event) {
    e.preventDefault()

    if (confirm("Change to \"Free\" plan?")) {
      this.freePlanFormTarget.submit()
    }
  }
}
