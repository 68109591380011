import { DateTime, Interval } from "luxon"

class Remembered {
  #assignment?: Assignment

  store(assignment: Assignment) {
    this.#assignment = assignment
  }

  // Only update the assignment if it matches the current one, we don't
  // want other assignments broadcast to the board to update it
  update(assignment: Assignment) {
    if (this.#assignment && this.#assignment?.id === assignment.id) {
      this.store(assignment)
    }
  }

  projectName() {
    return this.#assignment?.project?.name ?? ""
  }

  clear() {
    this.#assignment = undefined
  }

  // If the start time of the new assignment falls in between the remembered
  // assignment, then we want to adjust the end time to be the same as the
  // remembered one, so this will either pass through the original end time
  // or the remembered one
  applyEndTime(startTime: DateTime, endTime: DateTime) {
    if (!this.#assignment) {
      return endTime
    }

    const rememberedStartTime = DateTime.fromSeconds(this.#assignment.timestamps.start)
    const rememberedEndTime = DateTime.fromSeconds(this.#assignment.timestamps.end).endOf("day")

    if (Interval.fromDateTimes(rememberedStartTime, rememberedEndTime).contains(startTime)) {
      return rememberedEndTime
    }

    return endTime
  }
}

const remembered = new Remembered()

export default remembered
