import ApplicationController from "./controller"
import { renderStreamMessage } from "@hotwired/turbo"
import { navigation_show_path } from "@/routes"
import fetch from "@/util/fetch"
import debounce from "@/util/debounce"
import { TimePeriod } from "@/grid/time"

export default class extends ApplicationController<HTMLElement> {
  static values = {
    boardId: Number,
    offset: {
      type: Number,
      default: 0,
    },
    period: {
      type: String,
      default: TimePeriod.Week,
    },
  }
  static targets = ["period", "week", "month", "currentIteration"]

  declare readonly boardIdValue: number
  declare offsetValue: number
  declare periodValue: string

  declare readonly periodTargets: HTMLElement[]
  declare readonly weekTarget: HTMLElement
  declare readonly monthTarget: HTMLElement
  declare readonly currentIterationTarget: HTMLElement
  
  // refresh debounces updateIterations so we don't make repeated requests when a project 
  // with multiple assignments is updated. once the rest of the board is converted over to stimulus,
  // we may be able to refactor this better to avoid needing to debounce this event
  refresh = debounce(() => this.updateIterations(), 250)

  updateIterations() {
    fetch(navigation_show_path(), {
      body: JSON.stringify({
        board_id: this.boardIdValue,
        offset: this.offsetValue,
        period: this.periodValue,
      }),
      method: "POST",
    })
      .then(response => response.text())
      .then(html => {
        renderStreamMessage(html)
        this.dispatchGlobal("updateTimeGrid")
      })
  }

  periodValueChanged(value: number, previousValue: number) {
    if (value === previousValue) {
      return
    }

    this.offsetValue = 0
    this.updateIterations()
    this.#dispatchChange()
    this.dispatchGlobal("resetAssignments")
  }

  offsetValueChanged(value: number, previousValue: number) {
    if (value === previousValue) {
      return
    }

    this.#dispatchChange(previousValue)
    this.updateIterations()
  }

  keyPressed(e: KeyboardEvent) {
    if (document.activeElement) {
      const activeElement = document.activeElement.tagName.toLowerCase()
      if (activeElement === "input" || activeElement === "textarea") {
        return
      }
    }

    const LEFT = 37
    const RIGHT = 39

    if (e.keyCode === LEFT) {
      this.prev()
    }
    if (e.keyCode === RIGHT) {
      this.next()
    }
  }

  prev() {
    this.offsetValue--
  }

  next() {
    this.offsetValue++
  }

  today() {
    this.offsetValue = 0
  }

  togglePeriod({ params: { period }}: { params: { period: TimePeriod }}) {
    this.periodTargets.map(el => el.classList.remove("active"))
    this[`${period}Target`].classList.add("active")

    this.periodValue = period
  }

  #dispatchChange(previousOffset?: number) {
    const direction = this.offsetValue - (previousOffset ?? 0) > 0 ? "right" : "left"

    this.dispatch("change", {
      detail: {
        offset: this.offsetValue,
        period: this.periodValue,
        boardId: this.boardIdValue,
        direction,
      }
    })
  }
}
