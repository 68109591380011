import interact from "interactjs"
import timeGrid from "@/grid/time"
import remembered from "@/grid/remembered"
import assignmentCrud from "@/grid/assignment_crud"

class AssignmentRenderer {
  render(assignment: PreparedAssignment) {
    if (this.#isGrouped(assignment)) {
      assignment.assignments.map(assignment => assignment.element.style.display = "none")
      assignment.assignments[0].element.dataset.assignmentGroupValue = JSON.stringify(assignment.assignments.map(assignment => assignment.id))
    } else {
      delete assignment.element.dataset.assignmentGroupValue
    }

    Object.assign(assignment.element.style, {
      display: "block",
      top: `${assignment.preference.level * timeGrid.ITERATION_HEIGHT}px`,
      left: `${assignment.dimensions.offset * timeGrid.ITERATION_WIDTH}px`,
      width: `${(assignment.dimensions.duration) * timeGrid.ITERATION_WIDTH}px`,
    })

    // Indent the text if it's not visible
    const projectNameElement = assignment.element.querySelector("span")

    if (projectNameElement) {
      projectNameElement.style.paddingLeft = `${timeGrid.textIndent(assignment.dimensions.offset)}px`
    }

    // If it's new, focus the input
    const projectInputElement = assignment.element.querySelector("input")

    if (projectInputElement) {
      projectInputElement.value = remembered.projectName()
      projectInputElement.focus({ preventScroll: true })
    }
  }

  makeDraggable(assignment: Assignment & WithDomElement) {
    const position = {
      x: 0,
      y: 0,
    }

    interact(assignment.element)
      .draggable({
        autoScroll: false,
        modifiers: [
          interact.modifiers.snap({
            targets: [
              interact.snappers.grid({ x: timeGrid.ITERATION_WIDTH, y: 5 })
            ],
            offset: "startCoords",
            range: Infinity,
          }),
        ],
        listeners: {
          start() {
            assignment.element.classList.add("dragging")
          },
          end() {
            assignment.element.classList.remove("dragging")
          },
          move (event) {
            position.x += event.dx
            position.y += event.dy

            event.target.style.transform = `translate3d(${position.x}px, ${position.y}px, 0)`
          },
        },
        inertia: false,
      })
  }

  makeResizable(assignment: Assignment & WithDomElement, container: HTMLElement) {
    interact(assignment.element)
      .resizable({
        edges: {
          left: ".ui-resizable-w",
          right: ".ui-resizable-e",
        },
        modifiers: [
          interact.modifiers.snapSize({
            targets: [
              interact.snappers.grid({ x: timeGrid.ITERATION_WIDTH, y: 1 })
            ],
            range: Infinity,
          }),
        ],
        listeners: {
          start() {
            assignment.element.classList.add("resizing")
          },

          move: function (event) {
            let { x } = event.target.dataset

            x = (parseFloat(x) || 0) + event.deltaRect.left

            Object.assign(event.target.style, {
              width: `${event.rect.width}px`,
              transform: `translate3d(${x}px, 0, 0)`
            })

            Object.assign(event.target.dataset, { x })
          },

          end: () => {
            assignment.element.classList.remove("resizing")

            const assignmentsLeft = container.getBoundingClientRect().x
            const assignmentLeft = assignment.element.getBoundingClientRect().x - assignmentsLeft
            const assignmentWidth = assignment.element.getBoundingClientRect().width

            const startTime = timeGrid.adjustToOffset(timeGrid.calculateDateFromOffset(assignmentLeft))
            const endTime = timeGrid.adjustToOffset(timeGrid.calculateDateFromOffset(assignmentLeft + assignmentWidth - 1))

            assignmentCrud.update(assignment, startTime, endTime)
          },
        }
      })
  }

  #isGrouped(assignment: AssignmentOrGroup): assignment is GroupedAssignment<Assignment & WithDomElement> {
    return assignment.assignments !== undefined
  }
}

const assignmentRenderer = new AssignmentRenderer()

export default assignmentRenderer
