import ApplicationController from "./controller"

const formState = {
  content: "",
  visible: false
}

// Connects to data-controller="new-resource"
export default class extends ApplicationController<HTMLElement> {
  static targets = ["form", "prompt", "input"]

  static values = {
    boardId: Number
  }

  declare readonly formTarget: HTMLElement
  declare readonly promptTarget: HTMLElement
  declare readonly inputTarget: HTMLInputElement

  declare boardIdValue: number

  connect() {
    if (formState.visible) { 
      this.inputTarget.value = formState.content
      this.toggleForm()
    }
  }

  toggleForm() {
    formState.visible = this.element.classList.toggle("new-resource-form-active")

    if (formState.visible) {
      this.inputTarget.focus()
    }
  }

  closeWithKeyboard(e: KeyboardEvent) {
    if (e.code == "Escape" && formState.visible) {
      this.toggleForm()
    }
  }

  updateInputContent({ target } : { target: HTMLInputElement }) {
    formState.content = target.value ?? ""
  }

  clearState() {
    formState.content = ""
    formState.visible = false
  }

}
