import ApplicationController from "./controller"
import { TurboSubmitEndEvent } from "@hotwired/turbo"

export default class extends ApplicationController<HTMLElement> {
  static values = {
    y: Number,
    x: Number,
  }
  declare readonly xValue: number
  declare readonly yValue: number
  declare readonly hasXValue: boolean
  declare readonly hasYValue: boolean

  static targets = ["modalWindow"]
  declare readonly modalWindowTarget: HTMLElement

  connect() {
    if (!this.hasXValue || !this.hasYValue) {
      return
    }

    const flipBoundaryX = Math.round(window.innerWidth / 2)
    const flipBoundaryY = Math.round(window.innerHeight / 2)
    let xPosition = this.xValue
    let yPosition = this.yValue

    if (window.innerWidth - this.xValue < flipBoundaryX) {
      xPosition -= this.modalWindowTarget.clientWidth
    }
    if (window.innerHeight - this.yValue < flipBoundaryY) {
      yPosition -= this.modalWindowTarget.clientHeight
    }

    this.modalWindowTarget.style.top = `${yPosition}px`
    this.modalWindowTarget.style.left = `${xPosition}px`
  }

  // hide modal
  // action: "turbo-modal#hideModal"
  hideModal() {
    if (this.element.classList.contains("disallow-close")) { return }
    this.element.parentElement?.removeAttribute("src")
    this.element.remove()
  }

  // hide modal on successful form submission
  // action: "turbo:submit-end->turbo-modal#submitEnd"
  submitEnd(e: TurboSubmitEndEvent) {
    if (e.detail.success) {
      this.hideModal()
    }
  }

  // hide modal when clicking ESC
  // action: "keyup@window->turbo-modal#closeWithKeyboard"
  closeWithKeyboard(e: KeyboardEvent) {
    if (e.code == "Escape") {
      this.hideModal()
    }
  }

  // hide modal when clicking the overlay outside of modal
  // action: "click@window->turbo-modal#closeBackground"
  closeBackground({ target }: { target: HTMLLabelElement }) {
    if (target.classList.contains("overlay") || !this.modalWindowTarget.contains(target)) {
      this.hideModal()
    }
  }

  //
  // overlay-specific methods below here
  //
  
  // when the overlay is used for the video tutorial,
  // close the overlay when clicking outside of the overlay content
  closeOverlay({ target }: { target: HTMLLabelElement }) {
    if (!target.classList.contains("dont-close-with-click") && this.element.classList.contains("close-with-click")) {
      this.hideModal()
    }
  }
}
