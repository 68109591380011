import { Application } from "@hotwired/stimulus"
import { Settings } from "luxon"

const application = Application.start()

// Configure Stimulus development experience
application.debug = import.meta.env.DEV

// Set Luxon default timezone
Settings.defaultZone = "UTC"

export { application }
