import ApplicationController from "./controller"
import StripeHandler from "../util/stripeHandler"

// Connects to data-controller="account-settings"
export default class extends ApplicationController<HTMLElement> {
  declare readonly updateCardFormTarget: HTMLFormElement
  declare readonly hasUpdateCardFormTarget: boolean
  declare readonly emailValue: string
  declare readonly retryCardValue: boolean

  static values = {
    "email": String,
    "retryCard": Boolean,
  }
  static targets = ["updateCardForm"]

  connect() {
    if (this.hasUpdateCardFormTarget && this.retryCardValue) {
      this.updateCard()
    }
  }

  updateCard() {
    if (!this.hasUpdateCardFormTarget) {
      return
    }

    const form = this.updateCardFormTarget

    const params: StripeCheckoutOptions = {
      email: this.emailValue,
      description: "Update payment details",
      amount: 0,
      panelLabel: "Update card",
    }

    new StripeHandler(form, params).open()
  }

}
