import timeGrid, { TimePeriod } from "@/grid/time"

type ResolvedAssignments = {
  assignments: PreparedAssignmentsCollection
  maxLevel: number
}

class Solver {
  calculateOverlapping(assignments: AssignmentsCollection, group: boolean = false): ResolvedAssignments {
    let visibleAssignments = Object.values(assignments)
      .filter(assignment => timeGrid.isVisible(assignment))
      .sort((a, b) => {
        return a.timestamps.start - b.timestamps.start
      })

    if (group) {
      visibleAssignments = this.groupAssignments(visibleAssignments)
    }

    const mappedAssignments = visibleAssignments
      .map(assignment => {
        const dimensions = assignment.dimensions ?? timeGrid.calculateDimensions(assignment)

        // Save calculated dimensions
        assignment.dimensions = dimensions

        return {
          ...assignment,
          dimensions,
          offsets: [] as boolean[],
          preference: undefined,
        }
      })
      .sort((a, b) => {
        if (a.dimensions.offset === b.dimensions.offset) {
          return b.dimensions.duration - a.dimensions.duration
        }
        return a.dimensions.offset - b.dimensions.offset
      })

    let maxLevel = 0
    const preferences: Record<number, { level: number }> = {}

    const findPreference = (project: Project | null) => {
      return preferences[project?.id ?? -1]
    }

    const storePreference = (project: Project | null, level: number) => {
      const id = project?.id ?? -1

      if (preferences[id]) {
        preferences[id].level = level
      } else {
        preferences[id] = { level }
      }
    }

    for (let c = 0; c < mappedAssignments.length; c++) {
      const cell = mappedAssignments[c]

      // Check all assignments prior to this one and see if it overlaps
      for (let b = 0; b < c; b++) {
        const previousCell = mappedAssignments[b]
        if (previousCell.dimensions.offset + previousCell.dimensions.duration > cell.dimensions.offset) {
          cell.offsets[previousCell.preference.level] = true
        }
      }

      let level: number
      const preference = findPreference(cell.project)

      // If this project is already assigned a level, check to see if it's open or not
      // and place it there instead of the calculated one
      if (preference !== undefined && cell.offsets[preference.level] !== true) {
        level = preference.level
      } else {
        level = cell.offsets.findIndex((v: boolean) => v !== true)
        level = level >= 0 ? level : cell.offsets.length
      }

      // Save preference to object
      if (preference !== undefined && cell.offsets[preference.level] === true) {
        cell.preference = {
          level,
        }
      } else {
        // Save level preference
        storePreference(cell.project, level)
        cell.preference = findPreference(cell.project)
      }

      // Save max level to set row height
      maxLevel = Math.max(cell.preference.level, maxLevel)
    }

    return {
      assignments: mappedAssignments,
      maxLevel,
    }
  }

  groupAssignments(assignments: AssignmentOrGroup[]) {
    const groups = this.#groupByProject(assignments)
    const reduced = Object.values(groups).reduce(this.#groupClose.bind(this), [])

    // Remove any dimensions since they need to be recalculated
    return reduced.map(assignmentOrGroup => {
      assignmentOrGroup.dimensions = undefined

      return assignmentOrGroup
    })
  }

  #groupClose(accumulator: AssignmentOrGroup[], currentValue: AssignmentOrGroup[]): AssignmentOrGroup[] {
    let currentAssignment = null

    for (const assignment of Object.values(currentValue)) {
      // Calculate the dimensions in week period otherwise the duration will be 1 for a week
      assignment.dimensions ??= timeGrid.calculateDimensions(assignment, TimePeriod.Week)

      // Just reset, or first iteration, so set it
      if (currentAssignment === null) {
        currentAssignment = this.#cloneAssignment(assignment)

        continue
      }

      const { duration } = timeGrid.calculateDimensions({ timestamps: {
          start: currentAssignment.timestamps.end,
          end: assignment.timestamps.start,
        }}, TimePeriod.Week)

      if (duration <= timeGrid.MONTH_MINIMUM_DURATION) {
        // Include ourselves when creating it
        if (!currentAssignment.assignments) {
          currentAssignment.assignments = [this.#cloneAssignment(currentAssignment)]
        }

        currentAssignment.assignments.push(assignment)

        // Move the end time over as we add more
        currentAssignment.timestamps.end = Math.max(currentAssignment.timestamps.end, assignment.timestamps.end)
      } else {
        accumulator.push(currentAssignment)
        currentAssignment = this.#cloneAssignment(assignment)
      }
    }

    // Last one and not null
    if (currentAssignment) {
      accumulator.push(currentAssignment)
    }

    // Remove any assignments less than the minimum duration
    return accumulator.filter((assignmentOrGroup: AssignmentOrGroup) => {
      // It's a group, so it should pass
      if (assignmentOrGroup.assignments) {
        return true
      }

      return assignmentOrGroup.dimensions.duration >= timeGrid.MONTH_MINIMUM_DURATION
    })
  }

  #cloneAssignment(assignment: Assignment): AssignmentOrGroup {
    return {
      ...assignment,
      timestamps: { ...assignment.timestamps }
    }
  }

  #groupByProject(assignments: AssignmentOrGroup[]) {
    const groups: Record<number, AssignmentOrGroup[]> = {}

    assignments
      .map(assignment => {
        if (!groups[assignment.project_id]) {
          groups[assignment.project_id] = []
        }

        groups[assignment.project_id].push(assignment)
      })

    return groups
  }
}

const solver = new Solver()

export default solver
